

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/poursuite-responsabilite-medicale-medecin-scaled.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost21 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Responsabilité médicale : quelles conditions pour être dédommagé suite à une faute d’un professionnel de la santé? - Soumissions Avocat"
        description="Victime d’une erreur médicale? Consultez un avocat pour intenter un recours contre le professionnel responsable!"
        image={LeadImage}>
        <h1>Responsabilité médicale : quelles conditions pour être dédommagé suite à une faute d’un professionnel de la santé?</h1>

					
					
						<p><strong>Les médecins sont des professionnels tenus en haute estime dans la société québécoise</strong>, mais malgré tout, ils ne sont pas parfaits. L’erreur étant humaine, les médecins le sont également et il est possible qu’ils commettent une faute à votre endroit vous causant un préjudice indemnisable. Dans de telles circonstances, de quels recours disposez-vous pour obtenir réparation?</p>
<p><StaticImage alt="poursuite responsabilite medicale medecin" src="../images/poursuite-responsabilite-medicale-medecin-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>D’un recours en responsabilité médicale contre le médecin fautif</strong>! Comme tout professionnel, les médecins sont tenus à des obligations de moyens et de diligence à l’endroit de leurs patients au moment de prodiguer des soins. Lorsque ces obligations sont violées et que le patient en souffre, la porte de l’indemnisation s’ouvre.</p>
<p><strong>Voyez quelles sont les conditions nécessaires au recours en responsabilité médicale et consultez un avocat partenaire de Soumissions Avocat pour en discuter davantage!</strong></p>
<h2>Qu’est-ce qu’une « erreur médicale » au sens de la loi : les conditions d’un recours!</h2>
<p>Le recours en responsabilité médicale contre un médecin est en réalité une forme complexifiée du recours en responsabilité civile. En effet, cela implique que les trois mêmes éléments de faute, de préjudice et de lien de causalité doivent s’y retrouver, mais avec certaines adaptations nécessaires pour qu’ils s’appliquent à la sphère médicale.</p>
<p>C’est principalement <strong>la notion de faute médicale</strong> qui se trouve à être modifiée. Alors que dans un contexte civil on exige l’écart de conduite par rapport à celle qu’une personne raisonnable – le bon père de famille—aurait eu dans les mêmes circonstances, la faute médicale s’apprécie selon le comportement qu’un médecin prudent et diligent, placé dans les mêmes circonstances, aurait eu.</p>
<p>La preuve à faire est nettement plus complexe et nuancée, surtout lorsqu’il s’agit d’une erreur chirurgicale. Il faudra faire venir des experts du milieu pour éclairer le tribunal sur les différentes techniques de chirurgie et les diverses manœuvres médicales applicables selon les circonstances afin de déterminer s’il y a réellement eu une faute.
<strong>
Quant au préjudice, </strong>celui ne change que très peu en contexte médical, car il doit toujours s’agir d’une atteinte à un intérêt juridiquement protégé, qu’il soit moral, corporel ou matériel. Il va de soi qu’en matière de faute médicale, on retrouvera principalement des préjudices corporels et moraux découlant d’une faute.</p>
<p>C’est également à l’étape<strong> du lien de causalité </strong>que le bât blesse en matière médicale! En effet, il faudra faire la preuve que c’est la faute du médecin qui a causé les troubles de santé ou les dommages subis suite à l’intervention. Le lien causal pourra être simple à établir lorsque la personne était en pleine santé.</p>
<p>Par contre, lorsqu’on parle d’une personne avec des troubles récurrents, il pourra être difficile de déterminer, même en présence d’une faute, que c’est réellement celle-ci qui a causé le préjudice allégué.</p>
<p><strong>Vous trouvez que les standards semblent élevés pour prouver une faute médicale? </strong>Vous n’êtes pas seul à le penser, mais la situation n’est pas sans espoir pour autant, il suffit de vous entourer des bons experts juridiques et médicaux.</p>
<p><strong>C’est d’ailleurs à l’étape de vous trouver les bons alliés que Soumissions Avocat vous est d’une aide inestimable. Il suffit de remplir le formulaire en bas de page pour que l’on vous mette en contact avec les meilleurs experts du milieu!</strong></p>
<h2>Les étapes d’une poursuite en responsabilité médicale</h2>
<p>Préparez-vous pour une longue guerre si vous envisagez une poursuite en responsabilité médicale, car la bataille est loin d’être gagnée d’avance! Même si les médecins détiennent des couvertures d’assurance « béton », les assureurs comme les médecins en se laissent pas intimider facilement par une poursuite en responsabilité médicale.</p>
<p><strong> Vous devez donc vous attendre à accomplir les étapes suivantes pour obtenir le dédommagement convoité!</strong></p>
<p><StaticImage alt="etapes poursuite medicale avocat" src="../images/etapes-poursuite-medicale-avocat-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Tentative de règlement à l’amiable : </strong>Une fois la mise en demeure envoyée à votre médecin lui faisant part des dommages qu’il vous a causés, la première étape devrait être de tente de trouver un terrain d’entente. La <a href="https://jurigo.ca/avocat-mediation-arbitrage">médiation civile</a> est une option envisageable si la victime et le médecin sont tous deux de bonne foi, ce qui n’est pas toujours simple en matière de faute médicale.</p>
<p>Dépôt de la demande introductive d’instance : Lorsque les pourparlers échouent, le moment est venu de débuter les hostilités par le dépôt d’une demande introductive d’instance déposée soit à la Cour du Québec si le litige est d’une valeur de moins de 85 000$ ou encore à la Cour supérieure si le montant réclamé est supérieur à ce montant.</p>
<p>Signification aux parties et aux assureurs : Une demande introductive d’instance se doit d’être signifiée (envoyée par huissier) au médecin accusé d’une faute médicale. Comme celui-ci est assuré contre de tels recours, les assureurs sont pratiquement toujours partis au litige et reçoivent donc une copie de cette même demande.</p>
<p>Interrogatoire préalable : Advenant que la réclamation en jeu soit de plus de 30 000$, il sera possible de procéder à un interrogatoire préalable lors de laquelle le médecin et ses avocats auront le droit d’interroger la victime demanderesse dans le but d’obtenir davantage de détails nécessaires à la préparation d’une défense.
<strong>
Procès :</strong> C’est à ce moment tant convoité que la victime fait sa preuve de l’erreur médicale en s’appuyant sur l’expertise de médecins qualifiés, tandis que le médecin défendeur tentera de faire la même chose de son côté. Dans un cas comme dans l’autre, c’est la partie qui rend sa version « plus probable qu’improbable » aux yeux du juge qui parviendra à obtenir gain de cause.</p>
<p>Jugement : Plusieurs mois peuvent s’écouler entre le moment du dépôt de la demande introductive d’instance et la remise du jugement. Cela est d’autant plus vrai en matière médicale vu la remise de rapports d’expertise et de la complexité inhérente de tels dossiers.</p>
<h2>Pourquoi avez-vous absolument besoin d’un avocat spécialisé pour une erreur médicale?</h2>
<p>Est-ce que tous les avocats sont aptes à intenter un recours en responsabilité médicale ou faut-il plutôt opter pour un spécialiste? La réponse se trouve plutôt du côté du spécialiste! À juste titre, de tels litiges contiennent des composantes hautement spécialisées, ce qui fait en sorte que seul un vétéran médico-juriste saura vous obtenir gain de cause. On retrouve notamment les facteurs de complexité suivants!</p>
<p><StaticImage alt="avocat specialiste responsabilite medicale" src="../images/avocat-specialiste-responsabilite-medicale-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Une notion de faute propre au milieu médical. </strong>Prouver que le propriétaire d’un dépanneur aurait dû mettre du sel dans son entrée glacée et qu’il a commis une faute en l’omettant est une chose. Prouver qu’un médecin diligent n’aurait pas dû donner un coup de scalpel à tel endroit sur une artère du cœur en est une autre. Ainsi, comme la notion de faute en milieu médical est complexe à faire, la spécialisation des avocats est de mise.<strong>
</strong>
C’est d’ailleurs pour cette raison que<strong> l’intervention d’experts </strong>est nettement plus fréquente en matière médicale que dans les autres milieux. Le tribunal est un expert du droit et non de la médecine, mais pourtant, c’est lui qui détermine s’il y a bien eu présence de faute. Devant une telle incohérence, force est d’admettre l’indispensabilité des experts médicaux.<strong>
</strong>
De plus, les poursuites en responsabilité civile médicale sont<strong> des litiges susceptibles de s’étirer longtemps. </strong>Étant donné que les médecins sont pratiquement tous couverts par des assurances exhaustives, la poursuite met bien souvent en relation la victime, le médecin, les assureurs et parfois même l’hôpital elle-même. En présence d’un aussi grand nombre de parties prenantes, il n’est pas rare que ces litiges durent plusieurs années.</p>
<h2>L’intervention d’un expert médical est-il nécessaire pour prouver la faute?</h2>
<p>La faute représente un écart entre la conduite d’un médecin prudent et diligent placé dans les mêmes circonstances. Pensez bien à cette affirmation et vous réaliserez rapidement que solliciter des experts est essentiel pour faire la preuve de la faute.</p>
<p>Prenons un exemple. Vous êtes opéré au cœur pour un pontage et le médecin qui vous opère ne réussit pas à accomplir son objectif et, pire encore, il endommage votre cœur au passage. Même si vous subissez un préjudice évident, vous n’aurez droit à un dédommagement que si le médecin en question a commis une faute en raison de l’écart par rapport au médecin raisonnable dans les mêmes circonstances.</p>
<p><StaticImage alt="expert medical prouver faute avocat" src="../images/expert-medical-prouver-faute-avocat-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Comme ni vous ni votre avocat n’êtes qualifiés en matière procédés chirurgicaux cardiologiques, vous aurez besoin du coup de main d’un médecin spécialisé dans le domaine concerné. Celui-ci expliquera au tribunal les différentes méthodes de chirurgie employées dans le domaine afin d’établir si le médecin visé par la poursuite a bel et bien dérogé aux « règles de l’art ».</p>
<p><strong>Mais prenez garde!</strong> Votre expert médical demeure un tiers neutre au litige. C’est donc dire que son objectif n’est pas de débattre que votre point de vue est supérieur devant le juge, mais plutôt présenter de manière parfaitement objective que l’état des techniques médicales actuelles et, à partir de ce rapport, le juge constatera de lui-même si le comportement du médecin y déroge.</p>
<p>Rappelez-vous toutefois que le médecin accusé pourra lui aussi faire témoigner des experts de son milieu pour prouver qu’il n’a pas commis de faute. Que l’expert le plus convaincant gagne? Parfois oui, mais il arrive également que le juge ordonne à ces derniers de se rencontrer pour consolider leurs opinions et lui soumettre un rapport commun et cohérent.</p>
<p><strong>Lorsque cela est impossible, le juge aura la tâche de décider lequel des deux experts médicaux est le plus crédible à ses yeux. Une raison de plus de s’entourer des bons professionnels dès le départ, n’est-ce pas?</strong></p>
<h2>De combien de temps disposez-vous pour poursuivre un professionnel de la santé?</h2>
<p>Le droit de poursuivre quelqu’un, qu’il s’agisse d’un médecin ou d’un citoyen agissant à un autre titre, n’est pas éternel. Passé un certain délai de prescription, il vous deviendra donc impossible d’intenter l’action en justice convoitée, et ce, même si tous les éléments constitutifs de la faute, du préjudice et du lien de causalité étaient présents. En matière médicale, il faut donc faire très vite pour obtenir gain de cause.</p>
<p><strong>3 ans à partir de la connaissance du préjudice subit, voilà la règle qu’il vous faut retenir! </strong>Lorsqu’il s’agit d’un préjudice corporel, le calcul du délai pour intenter une action se calcul à partir du moment où la personne a connaissance de son droit d’action, et non pas à partir de la date de la faute elle-même.</p>
<p>C’est donc dire que si une opération au cœur bâclée se déroule le 1er janvier 2020, mais que la personne ne découvre les séquelles causées par ce mauvais traitement seulement le 1er janvier 2021, elle peut intenter son action contre le médecin fautif jusqu’au 1er janvier de l’année 2024.</p>
<p>En principe, le dédommagement accordé par le tribunal est final en ce sens qu’il s’agit de la seule et dernière indemnité à laquelle vous avez droit pour compenser le préjudice. La loi prévoit toutefois une exception en matière de préjudices corporels non stabilisés.</p>
<p>Le Code civil prévoit effectivement que vous pouvez revenir devant le tribunal pour demander une indemnité plus élevée pour une période maximale de 3 ans. Une fois ce délai dépassé, l’indemnité est figée et le créancier victime d’une erreur médicale ne peut plus réclamer davantage.</p>
<h2>Quelles sont les principales obligations des médecins à l’égard de leurs patients?</h2>
<p>Jusqu’à maintenant, nous avons surtout parlé des principes de faute applicables en matière d’erreur médicale d’ordre chirurgicale. Cependant, là ne s’arrête pas le principe de la responsabilité médicale, loin de là! En effet, les médecins se voient conférer, par l’effet de la loi, des obligations précises qu’ils sont tenus de respecter vis-à-vis leurs patients.</p>
<p><strong>Un manquement à l’une d’entre elles peut fonder un recours lorsqu’un préjudice en découle, alors les voici!</strong></p>
<p><StaticImage alt="obligations medecins patients poursuite" src="../images/obligations-medecins-patients-poursuite-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Obligation de renseignement : </strong>Le médecin qui s’apprête à prodiguer des soins à un patient a l’obligation de lui fournir tous les renseignements nécessaires à une prise de décision éclairée de sa part. Cela implique de lui expliquer le principe de l’intervention, les risques qui y sont associés, les séquelles possibles ainsi que les bienfaits qui en sont espérés. Une omission quant à l’un de ces éléments peut constituer une faute de la part du médecin engageant sa responsabilité civile.</p>
<p>Obligation de soin (diagnostic) : L’obligation du médecin quant à l’offre de soins se rattache d’abord à l’exactitude du diagnostic posé. Une erreur de diagnostic peut être grave, mais s’agit-il toujours d’une faute pour autant? Non, car les médecins sont tenus à une obligation de moyen et non de résultat lorsqu’ils posent un diagnostic.</p>
<p>C’est donc dire qu’il faudra établir la présence d’un <strong>diagnostic posé de façon fautive</strong>, ce qui se distingue du diagnostic erroné. En effet, le diagnostic fautif est celui prodigué par un médecin alors qu’il ne prend pas la peine de se renseigner adéquatement sur l’historique du patient, qui n’effectue pas les tests standards nécessaires et qui omet de contrôler le diagnostic en effectuant le bon suivi.</p>
<p>Ensuite, l’obligation de soins inclut également le devoir de les prodiguer de manière prudente et diligente en s’assurant que le consentement du patient de recevoir ces soins est libre, éclairé et basé sur toutes les informations nécessaires.</p>
<p>Obligation de suivi : Concernant le suivi, il s’agit de bien plus qu’une simple courtoisie lorsque votre médecin demande de vois revoir après une intervention. En effet, puisqu’il a l’obligation de s’assurer que le traitement procure les effets escomptés, et ce, selon une progression déterminée.</p>
<p>Cela implique que le médecin doit effectuer les suivis nécessaires auprès du patient en question, mais également qu’il doit s’assurer que les personnes qui le prendront en charge après son intervention auront toutes les informations nécessaires pour continuer ce même suivi médical.</p>
<p>Obligation de respect du secret professionnel : En vertu de son Code de déontologie, le médecin est tenu au secret professionnel, ce qui implique qu’il n’est pas en droit de communiquer des informations concernant le dossier médical d’un patient sans le consentement de ce dernier ou sans l’autorisation expresse de la loi.</p>
<p>Il faut toutefois apporter des nuances à cette obligation lorsqu’il est question d’un enfant âgé de <strong>moins de 14 ans</strong>, puisque ce sont les parents qui sont responsables de consentir à sa place. Dans tous les cas, lorsqu’il y aura réellement violation du secret professionnel, la seule preuve de la révélation illégale suffira à prouver la faute.</p>
<p>Que faire si vous croyez que votre médecin a manqué à l’une de ces obligations? Vous consultez un avocat se spécialisant en litiges de responsabilité médicale. Comment faire quand vous ne connaissez personne qui travaille dans ce domaine? Faites confiance à Soumissions Avocat pour vous dénicher pareil expert voilà comment!</p>
<h2>Consultez rapidement un avocat pour être dédommagé suite à une erreur médicale!</h2>
<p><strong>Vous souffrez de séquelles non prévues suite à une opération?</strong> Votre médecin vous a remis un mauvais diagnostic ou il a omis d’effectuer un suivi assez serré? Cela peut révéler des signes de comportement fautif de la part d’un médecin vous permettant d’exiger un dédommagement.</p>
<p><strong>Ne vous emballez toutefois pas trop vite!</strong> En effet, prouver une faute médicale contre un professionnel de la santé n’est guère un jeu d’enfant. Seule l’assistance juridique d’un avocat chevronné saura vous obtenir gain de cause.</p>
<p><strong>Vous croyez que votre situation respecte les critères énoncés ci-haut? Alors, n’hésitez plus et contactez Soumissions Avocat dès maintenant pour envisager votre recours en responsabilité médicale avec un avocat.</strong></p>
<p><strong>Rien ne sert de patienter plus longtemps, la mise en contact avec un avocat est gratuite et ne vous engage à rien, alors contactez-nous!</strong></p>
    </SeoPage>
)
export default BlogPost21
  